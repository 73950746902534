import { asyncRoute } from "@/router";
const state = {
  menu: []
};

const mutations = {
  SET_MENU: state => {
    console.log(22222222222222);
    state.menu = asyncRoute;
    console.log(state);
  }
};

const actions = {
  getMenu({ commit }) {
    const root = JSON.parse(localStorage.getItem("fns"));
    console.log(root);
    commit("SET_MENU");
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
