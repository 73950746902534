import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import "./style/theme/index.css";
import "./style/public.scss";
import "./icons";
import echarts from "echarts";
Vue.prototype.echarts = echarts;
import { getRoot } from "./utils/func";

import * as filters from "./api/filter.js";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});
import * as components from "./componemts"; // 全局组件
// 全局注册组件
Object.keys(components).forEach(key => {
  Vue.component(key, components[key]);
});

Vue.prototype.echarts = echarts;
Vue.prototype.dataList = {
  rootList: store.state.rootList
};
Vue.use(Element);
Vue.prototype.func = {
  getRoot: getRoot,
  formattingTime: filters["formattingTime"]
};
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
