<template>
  <div id="app" v-loading="loading">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  }
};
</script>
<style lang="scss"></style>
