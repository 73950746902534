export default {
  whiteList: ["resetPassword"], //资产信息查询
  loading: false,
  controlStatusList: [
    // { name: "节能模式", value: "1" },
    { name: "照明模式", value: "2" },
    { name: "远程温度控制", value: "3" },
    { name: "频率设置", value: "4" },
    // { name: "开机温度", value: "5" },
    // { name: "停机温度", value: "6" },
    // { name: "报警高温", value: "7" },
    // { name: "报警低温", value: "8" },
    { name: "远程开关设置", value: "9" },
    { name: "运行模式", value: "12" }
  ],
  rootList: [
    {
      name: "首页",
      value: 1,
      type: "menu"
    },
    {
      name: "资产管理",
      value: 2,
      type: "menu"
    },
    {
      name: "sim卡管理",
      value: 50,
      type: "menu"
    },
    {
      name: "设备监控",
      value: 3,
      type: "menu"
    },
    {
      name: "客户管理",
      value: 4,
      type: "menu"
    },
    {
      name: "异常管理",
      value: 5,
      type: "menu"
    },
    {
      name: "系统管理",
      value: 6,
      type: "menu"
    },
    {
      name: "分配组织",
      value: 7,
      type: "content"
    },
    {
      name: "状态监视",
      value: 8,
      type: "content"
    },
    {
      name: "设备状态设置",
      value: 9,
      type: "content"
    },
    {
      name: "基本报表信息",
      value: 10,
      type: "content"
    },
    {
      name: "投放报表信息",
      value: 11,
      type: "content"
    },
    {
      name: "客户信息",
      value: 12,
      type: "content"
    },
    {
      name: "SIM卡管理",
      value: 13,
      type: "menu"
    },
    {
      name: "PDA功能管理",
      value: 14,
      type: "menu"
    },
    {
      name: "图片管理",
      value: 15,
      type: "menu"
    },
    {
      name: "未入库设备管理",
      value: 16,
      type: "menu"
    },
    {
      name: "异常报警",
      value: 17,
      type: "menu"
    },
    {
      name: "设备巡检报表",
      value: 18,
      type: "content"
    },
    {
      name: "保修列表",
      value: 19,
      type: "content"
    },
    {
      name: "用户管理",
      value: 20,
      type: "menu"
    },
    {
      name: "角色管理",
      value: 21,
      type: "menu"
    },
    {
      name: "组织管理",
      value: 22,
      type: "menu"
    },
    {
      name: "操作日志查询",
      value: 23,
      type: "menu"
    },
    {
      name: "功能检测",
      value: 24,
      type: "menu"
    },
    {
      name: "资产信息查询",
      value: 25,
      type: "menu"
    },
    {
      name: "批量控制",
      value: 27,
      type: "button"
    },
    {
      name: "资产编辑",
      value: 29,
      type: "button"
    },
    {
      name: "未入库编辑",
      value: 30,
      type: "button"
    },
    {
      name: "批量删除",
      value: 31,
      type: "button"
    },
    {
      name: "控制器更换记录",
      value: 32,
      type: "menu"
    },
    {
      name: "历史数据",
      value: 33,
      type: "menu"
    },
    {
      name: "故障原因管理",
      value: 34,
      type: "menu"
    },
    {
      name: "历史设备查询",
      value: 42,
      type: "menu"
    },
    {
      name: "设备控制查询",
      value: 43,
      type: "menu"
    },
    {
      name: "批量修改状态",
      value: 45,
      type: "button"
    },
    {
      name: "客户配置管理",
      value: 46,
      type: "menu"
    },
    {
      name: "设备数据统计",
      value: 47,
      type: "menu"
    },
    {
      name: "告警批量重置",
      value: 48,
      type: "button"
    },
    {
      name: "批量重置基准定位",
      value: 49,
      type: "button"
    }
  ],
  nameMap: {
    内蒙古: "内蒙古",
    黑龙江: "黑龙江",
    吉林: "吉林省",
    辽宁: "辽宁省",
    北京: "北京市",
    天津: "天津市",
    河北: "河北省",
    山东: "山东省",
    山西: "山西省",
    宁夏: "宁夏回族",
    甘肃: "甘肃省",
    青海: "青海省",
    新疆: "新疆",
    西藏: "西藏",
    四川: "四川省",
    陕西: "陕西省",
    重庆: "重庆市",
    湖北: "湖北省",
    安徽: "安徽省",
    江苏: "江苏省",
    上海: "上海市",
    浙江: "浙江省",
    福建: "福建省",
    台湾: "台湾省",
    江西: "江西省",
    湖南: "湖南省",
    广东: "广东省",
    澳门: "澳门",
    香港: "香港",
    海南: "海南省",
    广西: "广西壮族自治区",
    贵州: "贵州省",
    云南: "云南省",
    河南: "河南省",
    南海诸岛: "南海诸岛"
  }
};
