import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);
export const asyncRoute = [
  {
    path: "/dashboard/index",
    name: "首页",
    id: 1,
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_home"
    },
    component: () => import("@/views/index/index")
  },
  {
    path: "/dashboard/assetAdmin",
    name: "资产管理",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_assets"
    },
    component: () => import("../views/AssetManagement/index.vue")
    // children: [
    //   {
    //     path: "/dashboard/assetAdmin/edit",
    //     name: "编辑",
    //     meta: {
    //       showRole: false
    //     },
    //     component: () => import("../views/AssetManagement/assetEdit")
    //   }
    // ]
  },
  {
    path: "/dashboard/assetSim",
    name: "SIM卡管理",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_home"
    },
    component: () => import("../views/simcardAdmin/index.vue")
  },
  {
    path: "/dashboard/assetAdmin/edit",
    name: "编辑",
    meta: {
      showRole: false
    },
    component: () => import("../views/AssetManagement/assetEdit")
  },
  {
    path: "/dashboard/equipmentMonitoring",
    name: "设备监控",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_equipment"
    },
    component: () => import("../views/equipmentMonitoring/index.vue")
  },
  {
    path: "/dashboard/equipmentControl",
    name: "设备控制查询",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_equipment"
    },
    component: () => import("../views/equipmentControl/index.vue")
  },
  {
    path: "/dashboard/functionDetection",
    name: "功能检测",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_equipment"
    },
    component: () => import("@/views/functionDetection/index")
  },
  {
    path: "/dashboard/equipmentDataStatistics",
    name: "设备数据统计",
    meta: {
      showRole: true,
      menu: true,
      icon: "ic_sidebar_equipment"
    },
    component: () => import("@/views/equipmentDataStatistics/index")
  },
  {
    path: "/dashboard/client",
    component: { render: h => h("router-view") },
    name: "客户管理",
    meta: { icon: "ic_sidebar_customer" },
    children: [
      {
        path: "/dashboard/client/assetQuery",
        name: "资产信息查询",
        meta: { showRole: true },
        component: () => import("../views/clientManage/assetQuery/index.vue")
      },
      {
        path: "/dashboard/client/imgManage",
        name: "图片管理",
        component: () => import("../views/clientManage/imgmanage/index"),
        meta: {
          showRole: true
        },
        children: [
          {
            path: "/dashboard/client/imgManage/add",
            name: "添加",
            component: () => import("../views/clientManage/imgmanage/imgAdd"),
            meta: {
              showRole: false
            }
          }
        ]
      },
      {
        path: "/dashboard/client/noStorage",
        name: "未入库设备管理",
        meta: { showRole: true },
        component: () => import("../views/clientManage/noStorageDevice.vue")
      },
      {
        path: "/dashboard/client/PDA",
        name: "PDA功能管理",
        meta: {
          showRole: true
        },
        component: () => import("../views/clientManage/PDA/PdaManage.vue"),
        children: [
          {
            path: "/dashboard/client/PDA/edit",
            name: "编辑/添加",
            meta: {
              showRole: false
            },
            component: () =>
              import("../views/clientManage/PDA/components/addFunction")
          }
        ]
      },
      {
        path: "/dashboard/client/configManage",
        name: "客户配置管理",
        meta: { showRole: true },
        component: () => import("../views/clientManage/configManage/index.vue")
      }
    ]
  },
  {
    path: "/dashboard/abnormityAdmin",
    name: "异常管理",
    component: { render: h => h("router-view") },
    meta: {
      icon: "ic_sidebar_abnormal"
    },
    children: [
      {
        path: "/dashboard/abnormityAdmin/equipmentHistory",
        name: "历史设备查询",
        meta: { showRole: true },
        component: () =>
          import("../views/abnormityAdmin/equipmentHistory/index")
      },
      {
        path: "/dashboard/abnormityAdmin/glitchReson",
        name: "故障原因管理",
        meta: { showRole: true },
        component: () => import("../views/abnormityAdmin/glitchReson/index")
      },
      {
        path: "/dashboard/abnormityAdmin/glitchReson/Add",
        name: "添加故障原因",
        meta: {
          showRole: false
        },
        component: () => import("../views/abnormityAdmin/glitchReson/add")
      },
      {
        path: "/dashboard/abnormityAdmin/warning",
        name: "异常报警",
        meta: { showRole: true },
        component: () =>
          import("../views/abnormityAdmin/abnormityWarning/index")
      },
      {
        path: "/dashboard/abnormityAdmin/controllerHistory",
        name: "控制器更换记录",
        meta: { showRole: true },
        component: () =>
          import("../views/abnormityAdmin/controllerHistory/index")
      },
      {
        path: "/dashboard/abnormityAdmin/patrolList",
        name: "设备巡检报表",
        meta: {
          showRole: true
        },
        component: () => import("../views/abnormityAdmin/patrolList/index")
      },
      {
        path: "/dashboard/abnormityAdmin/particulars",
        name: "查看详情",
        meta: {
          showRole: false
        },
        component: () =>
          import("../views/abnormityAdmin/patrolList/particulars")
      },
      {
        path: "/dashboard/abnormityAdmin/repairsList",
        name: "报修列表",
        meta: {
          showRole: false
        },
        component: () => import("../views/abnormityAdmin/repairsList/index")
      }
    ]
  },
  // {
  //   path: "/dashboard",
  //   component: { render: h => h("router-view") },
  //   children: [
  {
    path: "/dashboard/equipmentMonitoring/particulars",
    name: "详情",
    meta: {
      showRole: false
    },
    component: () => import("../views/equipmentMonitoring/particulars")
  },
  {
    path: "/dashboard/purityJump",
    name: "识别详情",
    meta: {
      showRole: false
    },
    component: () => import("../views/equipmentMonitoring/purityJump")
  },
  // {
  //   path: "/dashboard/historyData",
  //   name: "历史数据",
  //   meta: {
  //     showRole: true,
  //     menu: true,
  //     icon: "ic_sidebar_equipment"
  //   },
  //   component: () => import("@/views/historyData/index")
  // },
  {
    path: "/dashboard/systemSet",
    name: "系统管理",
    component: { render: h => h("router-view") },
    meta: { icon: "ic_sidebar_system" },
    children: [
      {
        path: "/dashboard/systemSet/Control",
        name: "用户管理",
        meta: { showRole: true },
        component: () => import("../views/systemSet/userControl")
      },
      {
        path: "/dashboard/systemSet/Add",
        name: "添加用户",
        meta: {
          showRole: false
        },
        component: () => import("../views/systemSet/addUser")
      },
      {
        path: "/dashboard/systemSet/roleControl",
        name: "角色管理",
        meta: { showRole: true },
        component: () => import("../views/systemSet/roleControl")
      },
      {
        path: "/dashboard/systemSet/addRoleControl",
        name: "添加角色",
        meta: {
          showRole: false
        },
        component: () => import("../views/systemSet/addRoleControl")
      },
      {
        path: "/dashboard/systemSet/organizationAdmin",
        name: "组织管理",
        meta: { showRole: true },
        component: () => import("../views/systemSet/organizationAdmin")
      },
      {
        path: "/dashboard/systemSet/log",
        name: "操作日志查询",
        meta: { showRole: true },
        component: () => import("../views/systemSet/logAdmin")
      }
    ]
  }
  //   ]
  // }
];
const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/login/index.vue")
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/reset/resetPassword")
  },
  {
    path: "/layouts",
    component: () =>
      import(/* webpackChunkName: "user" */ "../layouts/basicLayout"),
    children: [
      //  dashboard
      {
        path: "/dashboard/message",
        name: "消息列表",
        meta: {
          showRole: true
        },
        component: () => import("@/views/messageList/index")
      },
      {
        path: "/dashboard/account",
        name: "账号管理",
        component: { render: h => h("router-view") },
        children: [
          {
            path: "/dashboard/account/PIM",
            name: "个人资料",
            component: () => import("../views/systemSet/PIM")
          },
          {
            path: "/dashboard/account/alterPassword",
            name: "修改密码",
            component: () => import("../views/systemSet/alterPassword")
          }
        ]
      },
      ...asyncRoute
    ]
  }
];

const router = new VueRouter({
  routes
});
const whiteList = store.state.whiteList;
router.beforeEach((to, from, next) => {
  console.log(1);
  //我在这里模仿了一个获取用户信息的方法
  let isLogin = localStorage.getItem("token");
  if (isLogin) {
    //如果用户信息存在则往下执行。
    // let data = JSON.parse(localStorage.getItem("fnList")) ;

    next();
  } else {
    //如果用户token不存在则跳转到login页面
    if (to.path === "/login" || whiteList.find(el => el === to.name)) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
