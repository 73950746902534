import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import menu from "./modules/menu";
Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    }
  },
  actions: {},
  modules: { menu }
});
